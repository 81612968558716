import { createContext, useEffect, useState } from "react";
import { redirect } from "react-router-dom";

const SettingsContext = createContext(null);

function SettingsProvider({ children }) {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handleGetSettings = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_CZATER_API_URL}/custom-page/${window.location.host}`
    );

    if (response.status === 404) {
      return redirect(process.env.REACT_APP_CZATER_URL);
    }
    response = await response.json();

    setSettings(response.data);
  };

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
