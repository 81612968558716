import {
  Grid,
  IconButton,
  Button,
  Toolbar,
  Box,
  AppBar,
  Drawer,
} from "@mui/material";
import { useContext, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/x-white.svg";
import { SettingsContext } from "../../context/SettingsContext";

export default function NavBar() {
  const settings = useContext(SettingsContext);

  const [open, setOpen] = useState(false);

  const changeOpen = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {settings.logo ? (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  className="navbar-logo"
                  LinkComponent={Link}
                  to="/"
                >
                  <img
                    src={`${process.env.REACT_APP_CZATER_URL}/assets/uploads/logo/${settings.logo}`}
                    alt="logo firmy"
                    width={100}
                    onError={(e) => (e.target.style.display = "none")}
                  />
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: "none", sm: "block" },
                "& a": {
                  padding: { xs: "0.75rem 0.2rem", md: "0.75rem 1.5rem" },
                },
              }}
              flexGrow={2}
            >
              <Grid container justifyContent={"flex-end"}>
                <Button
                  variant="navbar"
                  className="btn-nav-about"
                  LinkComponent={Link}
                  to="/#about"
                  smooth
                  data-text="O nas"
                >
                  O nas
                </Button>
                <Button
                  variant="navbar"
                  className="btn-nav-offer"
                  LinkComponent={Link}
                  to="/#offer"
                  smooth
                  data-text="Nasza oferta"
                  sx={{ textAlign: "center" }}
                >
                  Nasza oferta
                </Button>
                <Button
                  className="btn-nav-contact"
                  variant="navbar"
                  LinkComponent={Link}
                  to="/#contact"
                  smooth
                  data-text="Kontakt"
                >
                  Kontakt
                </Button>
                {settings?.categories?.length ? (
                  <Button
                    variant="outlined-navbar"
                    className="navbar-offer-btn"
                    LinkComponent={Link}
                    to="/offersCategories"
                  >
                    Pełna oferta
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            {/* MOBILE */}
            <IconButton
              onClick={changeOpen}
              sx={{
                display: { xs: "inline-flex", sm: "none" },
              }}
            >
              <HamburgerIcon />
            </IconButton>
            <Drawer
              open={open}
              onClose={changeOpen}
              sx={{
                "& .MuiDrawer-paper": {
                  width: 300,
                  backgroundColor: "secondary.main",
                  "& a": {
                    color: "secondary.contrastText",
                  },
                },
              }}
            >
              <IconButton
                sx={{ alignSelf: "flex-end", padding: "1rem" }}
                onClick={changeOpen}
              >
                <CrossIcon width={40} height={40} />
              </IconButton>
              <Grid
                container
                alignItem="center"
                justifyContent={"center"}
                columns={1}
                flexDirection="column"
                padding="1rem"
                height={"70%"}
              >
                <Grid container flexDirection={"column"} marginBottom={"4rem"}>
                  <Button
                    variant="navbar"
                    onClick={changeOpen}
                    LinkComponent={Link}
                    to="/#about"
                    smooth
                  >
                    O nas
                  </Button>
                  <Button
                    variant="navbar"
                    onClick={changeOpen}
                    LinkComponent={Link}
                    to="/#offer"
                    smooth
                  >
                    Nasza oferta
                  </Button>
                  <Button
                    variant="navbar"
                    onClick={changeOpen}
                    LinkComponent={Link}
                    to="/#contact"
                    smooth
                  >
                    Kontakt
                  </Button>
                </Grid>
                <Button variant="outlined" className="navbar-offer-btn">
                  Pełna oferta
                </Button>
              </Grid>
            </Drawer>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
