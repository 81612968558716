import { createTheme } from "@mui/material";
import theme from "./theme";

const electronicTheme = createTheme(theme, {
  palette: {
    primary: {
      main: "#1A70C0",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#B1BC2A",
      contrastText: "#FFF",
    },
    tertiary: {
      main: "#FF6D1B",
      contrastText: "#FFF",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "navbar" },
          style: {
            color: "#000",
            flexBasis: "15%",
            fontWeight: "normal",
            transition: "color 1ms",
            "&:hover": {
              fontWeight: "bold",
              backgroundColor: "transparent",
            },
            "&.btn-nav-about:hover": {
              color: "#000",
            },
            "&.btn-nav-offer:hover": {
              color: "#FF6D1B",
            },
            "&.btn-nav-contact:hover": {
              color: "#1A70C0",
            },
            [theme.breakpoints.only("sm")]: {
              flexBasis: "24%",
              padding: 0,
            },
            [theme.breakpoints.only("md")]: {
              flexBasis: "21%",
            },
            [theme.breakpoints.only("lg")]: {
              flexBasis: "15%",
            },
            [theme.breakpoints.only("xl")]: {
              flexBasis: "11%",
            },
          },
        },
        {
          props: { variant: "outlined-navbar" },
          style: {
            color: "#FF6D1B",
            borderColor: "#FF6D1B",
            borderWidth: 2,
            borderStyle: "solid",
            fontWeight: "bold",
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#FF6D1B",
            },
            [theme.breakpoints.only("xs")]: {
              margin: 0,
              marginLeft: 0,
              flexBasis: "24%",
            },
            [theme.breakpoints.only("sm")]: {
              margin: "1rem 0rem",
              marginLeft: "0rem",
              flexBasis: "24%",
            },
            [theme.breakpoints.only("md")]: {
              margin: "1.5rem",
              marginLeft: "1rem",
            },
            [theme.breakpoints.up("lg")]: {
              margin: "1.5rem",
              marginLeft: "4rem",
            },
          },
        },
      ],
      styleOverrides: {
        outlined: {
          "&:hover": {
            borderColor: "#FF6D1B",
            backgroundColor: "#FF6D1B",
          },
          "&.btn-offer-section:hover": {
            color: "#FF6D1B",
          },
        },
        contained: {
          "&:hover": {
            color: "#1A70C0",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.desc-main-section": {
            backgroundColor: "#000",
            [theme.breakpoints.down("md")]: {
              backgroundColor: "rgb(0, 0, 0, 0.9)",
            },
          },
          "&.desc-offer-section": {
            backgroundColor: "#FF6D1B",
            [theme.breakpoints.down("md")]: {
              backgroundColor: "rgb(255, 109, 27, 0.9)",
            },
          },
          "&.desc-contact-section": {
            backgroundColor: "#1A70C0",
          },
          "&.category-item": {
            "&:hover": {
              borderColor: "#FF6D1B",
              ".MuiTypography-root": {
                color: "#FF6D1B",
              },
            },
          },
          "&.container-main-section": {
            [theme.breakpoints.only("lg")]: {
              backgroundSize: "45%",
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.title-about-section": {
            color: "#000",
          },
        },
      },
    },
  },
});

export default electronicTheme;
