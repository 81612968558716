import { createTheme } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

theme = createTheme(theme, {
  palette: {
    primary: {
      contrastText: "#FFF",
    },
    secondary: {
      contrastText: "#FFF",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          color: "#000",
          "& .MuiIconButton-root": {
            margin: "1.5rem",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "navbar" },
          style: {
            color: "#000",
            flexBasis: "15%",
            fontWeight: "normal",
            transition: "color 1ms",
            "&:hover": {
              fontWeight: "bold",
              backgroundColor: "transparent",
            },
            "&.btn-nav-about:hover": {
              color: "#B1BC2A",
            },
            "&.btn-nav-offer:hover": {
              color: "#FF6969",
            },
            "&.btn-nav-contact:hover": {
              color: "#FF9A3D",
            },
            [theme.breakpoints.only("sm")]: {
              flexBasis: "24%",
              padding: 0,
            },
            [theme.breakpoints.only("md")]: {
              flexBasis: "21%",
            },
            [theme.breakpoints.only("lg")]: {
              flexBasis: "15%",
            },
            [theme.breakpoints.only("xl")]: {
              flexBasis: "11%",
            },
          },
        },
        {
          props: { variant: "outlined-navbar" },
          style: {
            color: "#FF6969",
            borderColor: "#FF6969",
            borderWidth: 2,
            borderStyle: "solid",
            fontWeight: "bold",
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#FF6969",
            },
            [theme.breakpoints.only("xs")]: {
              margin: 0,
              marginLeft: 0,
              flexBasis: "24%",
            },
            [theme.breakpoints.only("sm")]: {
              margin: "1rem 0rem",
              marginLeft: "0rem",
              flexBasis: "24%",
            },
            [theme.breakpoints.only("md")]: {
              margin: "1.5rem",
              marginLeft: "1rem",
            },
            [theme.breakpoints.up("lg")]: {
              margin: "1.5rem",
              marginLeft: "4rem",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: "0.5rem 2rem",
          textTransform: "none",
          fontSize: "1rem",
          borderRadius: 50,
          color: "#FFF",
          fontWeight: "bold",
        },
        outlined: {
          color: "#FFF",
          borderColor: "#FFF",
          borderWidth: 2,
          "&:hover": {
            color: "#FFF",
            borderWidth: 2,
          },
          "&.btn-offer-section:hover": {
            borderColor: "#FFF",
            backgroundColor: "#FFF",
          },
          "&.btn-contact-section": {
            flexBasis: "33%",
            [theme.breakpoints.only("xs")]: {
              flexBasis: "100%",
            },
          },
          "&.btn-contact-section:hover": {
            color: "#FFF",
          },
          "&.btn-offer": {
            color: "#C0C0C0",
            marginTop: "2rem",
            borderColor: "#C0C0C0",
            "&:hover": {
              color: "#FFF",
              borderColor: "transparent",
            },
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            borderColor: "#FFF",
            backgroundColor: "#FFF",
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          borderWidth: 0,
          backgroundColor: "#FFFFFF4D",
          "& fieldset": {
            borderWidth: 0,
          },
          "& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
            borderRadius: 15,
            borderColor: "#FFF",
          },
          "&:hover": {
            backgroundColor: "#FFF",
          },
          "& .Mui-focused input": {
            backgroundColor: "#FFF",
            borderRadius: 15,
          },
          "& .Mui-focused textarea": {
            backgroundColor: "#FFF",
          },
          "& .Mui-focused.MuiInputBase-multiline": {
            backgroundColor: "#FFF",
            borderRadius: 15,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.desc-main-section": {
            padding: "4rem",
            [theme.breakpoints.only("xs")]: {
              padding: "2rem",
            },
          },
          "&.desc-offer-section": {
            padding: "4rem",
            [theme.breakpoints.only("xs")]: {
              padding: "2rem",
            },
          },
          "&.desc-contact-section": {
            padding: "4rem",
            color: "#FFF",
            [theme.breakpoints.only("xs")]: {
              padding: "2rem",
            },
          },
          "&.category-item": {
            border: "4px solid #C0C0C0",
            borderRadius: 6,
            textAlign: "center",
            padding: "2rem",
            flexBasis: "40%",
            cursor: "pointer",
            "&:hover": {
              borderColor: "#FF6D1B",
              img: {
                fill: "#FF6D1B",
              },
              ".MuiTypography-root": {
                color: "#FF6D1B",
              },
            },
          },
          "&.footer": {
            color: "#FFF",
            backgroundColor: "gray",
            padding: "4rem",
            [theme.breakpoints.only("xs")]: {
              padding: "2rem",
            },
          },
          "& a.footer-link": {
            color: "#FFF",
            textDecoration: "none",
            lineHeight: "1.5",
            "&:hover": {
              fontWeight: "bold",
            },
          },
        },
      },
    },
  },
});

export default theme;
