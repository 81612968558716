import { useContext } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function OffersCategories() {
  const settings = useContext(SettingsContext);
  const navigate = useNavigate();

  const handleCategoryClick = (id) => {
    navigate(`/categoryOffers/${id}`);
  };

  return (
    <Grid container flexGrow={1} sx={{ padding: "4rem" }}>
      <Typography variant="h2" color="#FF6D1B" fontWeight={"bold"}>
        Nasza oferta
      </Typography>
      <Grid container>
        {settings.categories &&
          settings.categories.map((category) => (
            <Grid
              item
              key={category.idAllegroCategories}
              className="category-item"
              onClick={() => handleCategoryClick(category.idAllegroCategories)}
            >
              <Grid
                container
                justifyContent={"space-evenly"}
                alignItems={"center"}
                sx={{ height: "100%" }}
              >
                <img
                  src={`/assets/industries/electronic/icons/${category.shortName}.svg`}
                  alt="computer"
                />
                <Typography
                  sx={{ color: "#C0C0C0" }}
                  variant="h4"
                  fontWeight={"bold"}
                  className="category-text"
                >
                  {category.external_name}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}
