import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { ThemeProvider } from "@mui/material";
import { SettingsContext } from "./context/SettingsContext";
import { useContext } from "react";

import electronicTheme from "./styles/themes/electronic.theme";
import fashionTheme from "./styles/themes/fashion.theme";
import beautyTheme from "./styles/themes/beauty.theme";
import homeTheme from "./styles/themes/home.theme";
import supermarketTheme from "./styles/themes/supermarket.theme";
import babyTheme from "./styles/themes/baby.theme";
import healthTheme from "./styles/themes/health.theme";
import cultureTheme from "./styles/themes/culture.theme";
import sportTheme from "./styles/themes/sport.theme";
import motoTheme from "./styles/themes/moto.theme";
import propertyTheme from "./styles/themes/property.theme";
import artTheme from "./styles/themes/art.theme";
import companyTheme from "./styles/themes/company.theme";

const themes = {
  beauty: beautyTheme,
  electronic: electronicTheme,
  fashion: fashionTheme,
  home: homeTheme,
  supermarket: supermarketTheme,
  baby: babyTheme,
  health: healthTheme,
  culture: cultureTheme,
  sport: sportTheme,
  moto: motoTheme,
  property: propertyTheme,
  art: artTheme,
  company: companyTheme,
};

function App() {
  const settings = useContext(SettingsContext);

  return (
    <ThemeProvider theme={themes[settings.industry || "beauty"]}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
