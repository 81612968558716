import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { SettingsContext } from "../../context/SettingsContext";

export default function AboutSection() {
  const settings = useContext(SettingsContext);

  return (
    <Grid
      container
      id="about"
      sx={{ padding: { xs: "2rem", sm: "2rem 4rem" } }}
      flexDirection={"column"}
    >
      <Typography
        variant="h2"
        paddingBottom="2rem"
        className="title-about-section"
        fontWeight={"bold"}
      >
        O nas
      </Typography>
      <Typography variant="body1">{settings.aboutDescription}</Typography>
    </Grid>
  );
}
