import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { Grid } from "@mui/material";

import "../../styles/index.css";

function Layout() {
  return (
    <Grid
      container
      sx={{ minHeight: "100vh" }}
      flexDirection="column"
      justifyContent="space-between"
    >
      <NavBar />
      <Outlet />
      <Footer />
    </Grid>
  );
}

export default Layout;
