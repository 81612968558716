import { Button, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { SettingsContext } from "../../context/SettingsContext";

import beautyImg from "../../assets/industries/beauty/offer-section.png";
import electronicImg from "../../assets/industries/electronic/offer-section.png";
import fashionImg from "../../assets/industries/fashion/offer-section.png";
import homeImg from "../../assets/industries/home/offer-section.png";
import supermarketImg from "../../assets/industries/supermarket/offer-section.png";
import babyImg from "../../assets/industries/baby/offer-section.png";
import healthImg from "../../assets/industries/health/offer-section.png";
import cultureImg from "../../assets/industries/culture/offer-section.png";
import sportImg from "../../assets/industries/sport/offer-section.png";
import motoImg from "../../assets/industries/moto/offer-section.png";
import propertyImg from "../../assets/industries/property/offer-section.png";
import artImg from "../../assets/industries/art/offer-section.png";
import companyImg from "../../assets/industries/company/offer-section.png";
import { Link } from "react-router-dom";

const images = {
  beauty: beautyImg,
  electronic: electronicImg,
  fashion: fashionImg,
  home: homeImg,
  supermarket: supermarketImg,
  baby: babyImg,
  health: healthImg,
  culture: cultureImg,
  sport: sportImg,
  moto: motoImg,
  property: propertyImg,
  art: artImg,
  company: companyImg,
};

export default function OfferSection() {
  const settings = useContext(SettingsContext);

  return (
    <Grid
      container
      id="offer"
      flexDirection={"row-reverse"}
      sx={{
        color: "#FFF",
        backgroundImage: `url(${images[settings.industry]})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left top",
        backgroundSize: { xs: "cover", md: "64%", lg: "36%", xl: "34%" },
        margin: "4rem 0",
      }}
      className="container-offer-section"
    >
      <Grid item xs={12} md={6} lg={8} className="desc-offer-section">
        <Grid container flexDirection={"column"}>
          <Typography variant="h2" fontWeight={"bold"}>
            Nasza oferta
          </Typography>
          <Typography variant="body1" padding={"2rem 0rem"}>
            {settings.offerDescription}
          </Typography>
          {settings?.categories?.length ? (
            <Grid container>
              <Button
                variant="outlined"
                className="btn-offer-section"
                LinkComponent={Link}
                to="/offersCategories"
              >
                Przejdź do oferty
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
