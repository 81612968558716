import { Grid, Button, Typography, IconButton } from "@mui/material";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/icons/youtube.svg";
import { useContext } from "react";
import { SettingsContext } from "../../context/SettingsContext";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer() {
  const settings = useContext(SettingsContext);

  return (
    <Grid
      container
      flexWrap={{ xs: "wrap", md: "nowrap" }}
      className="footer"
      columns={{ xs: 1, md: 3 }}
    >
      <Grid item xs={4}>
        <Grid container flexDirection="column" alignItems={"start"}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            className="footer-logo"
          >
            <Typography variant="h2">LOGO</Typography>
          </IconButton>
          {settings.company && Object.keys(settings.company).length > 0 ? (
            <>
              <Typography>
                {settings?.company?.name}
                <br />
                {settings?.company?.street +
                  " " +
                  settings?.company?.property_number}
                {settings?.company?.apartment_number
                  ? "/" + settings.company?.apartment_number
                  : ""}
                <br />
                {settings?.company?.city + " " + settings?.company?.zip_code}
              </Typography>
              <br />
              <Typography>NIP: {settings?.company?.nip}</Typography>
              <Typography>REGON: {settings?.company?.regon}</Typography>
              <Typography>KRS: {settings?.company?.krs}</Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        alignSelf="center"
        sx={{ margin: { xs: "2rem 0rem", sm: "0" } }}
      >
        <Grid container flexDirection="column" alignItems={"start"}>
          <Link to="/#about" smooth className="footer-link">
            O nas
          </Link>
          <Link to="/#offer" smooth className="footer-link">
            Nasza oferta
          </Link>
          <Link to="/#contact" smooth className="footer-link">
            Kontakt
          </Link>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ alignSelf: { xs: "flex-start", sm: "flex-end" } }}
      >
        <Grid
          container
          alignItems={"center"}
          sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
        >
          <IconButton sx={{ width: 50 }}>
            <FacebookLogo />
          </IconButton>
          <IconButton sx={{ width: 50 }}>
            <InstagramLogo />
          </IconButton>
          <IconButton sx={{ width: 50 }}>
            <YoutubeLogo />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
