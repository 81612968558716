import AboutSection from "../components/sections/AboutSection";
import ContactSection from "../components/sections/ContactSection";
import MainSection from "../components/sections/MainSection";
import OfferSection from "../components/sections/OfferSection";

function Main() {
  return (
    <>
      <MainSection />
      <AboutSection />
      <OfferSection />
      <ContactSection />
    </>
  );
}

export default Main;
