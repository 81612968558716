import { createTheme } from "@mui/material";
import theme from "./theme";

const sportTheme = createTheme(theme, {
  palette: {
    primary: {
      main: "#0ADCDC",
    },
    secondary: {
      main: "#292929",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "navbar" },
          style: {
            color: "#000",
            flexBasis: "15%",
            fontWeight: "normal",
            transition: "color 1ms",
            "&:hover": {
              fontWeight: "bold",
              backgroundColor: "#FFF",
            },
            "&.btn-nav-about:hover": {
              color: "#292929",
            },
            "&.btn-nav-offer:hover": {
              color: "#D9DD00",
            },
            "&.btn-nav-contact:hover": {
              color: "#0ADCDC",
            },
            [theme.breakpoints.only("sm")]: {
              flexBasis: "24%",
              padding: 0,
            },
            [theme.breakpoints.only("md")]: {
              flexBasis: "21%",
            },
            [theme.breakpoints.only("lg")]: {
              flexBasis: "15%",
            },
            [theme.breakpoints.only("xl")]: {
              flexBasis: "11%",
            },
          },
        },
        {
          props: { variant: "outlined-navbar" },
          style: {
            color: "#D9DD00",
            borderColor: "#D9DD00",
            borderWidth: 2,
            borderStyle: "solid",
            fontWeight: "bold",
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#D9DD00",
            },
            [theme.breakpoints.only("xs")]: {
              margin: 0,
              marginLeft: 0,
              flexBasis: "24%",
            },
            [theme.breakpoints.only("sm")]: {
              margin: "1rem 0rem",
              marginLeft: "0rem",
              flexBasis: "24%",
            },
            [theme.breakpoints.only("md")]: {
              margin: "1.5rem",
              marginLeft: "1rem",
            },
            [theme.breakpoints.up("lg")]: {
              margin: "1.5rem",
              marginLeft: "4rem",
            },
          },
        },
      ],
      styleOverrides: {
        outlined: {
          "&:hover": {
            borderColor: "#D9DD00",
            backgroundColor: "#D9DD00",
          },
          "&.btn-offer-section:hover": {
            color: "#D9DD00",
          },
        },
        contained: {
          "&:hover": {
            color: "#0ADCDC",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.desc-main-section": {
            backgroundColor: "#292929",
            [theme.breakpoints.down("md")]: {
              backgroundColor: "rgb(41, 41, 41, 0.9)",
            },
          },
          "&.desc-offer-section": {
            backgroundColor: "#D9DD00",
            [theme.breakpoints.down("md")]: {
              backgroundColor: "rgb(217, 221, 0, 0.9)",
            },
          },
          "&.desc-contact-section": {
            backgroundColor: "#0ADCDC",
          },
          "&.category-item": {
            "&:hover": {
              borderColor: "#D9DD00",
              ".MuiTypography-root": {
                color: "#D9DD00",
              },
            },
          },
          "&.container-main-section": {
            [theme.breakpoints.only("lg")]: {
              backgroundSize: "34%",
            },
          },
          "&.container-offer-section": {
            [theme.breakpoints.only("md")]: {
              backgroundSize: "70%",
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.title-about-section": {
            color: "#292929",
          },
        },
      },
    },
  },
});

export default sportTheme;
