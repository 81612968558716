import { Button, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SettingsContext } from "../context/SettingsContext";

export default function CategoryOffers() {
  const settings = useContext(SettingsContext);
  const params = useParams();

  const [category, setCategory] = useState({});
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    if (params.id) {
      refreshOffers();
      refreshCategory();
    }
  }, [params.id]);

  const refreshCategory = async () => {
    if (settings.categories && settings.categories.length > 0) {
      settings.categories.forEach((category) => {
        if (category.idAllegroCategories == params.id) {
          setCategory(category);
        }
      });
    }
  };

  const refreshOffers = async () => {
    const result = await fetch(
      `${process.env.REACT_APP_CZATER_API_URL}/custom-page/allegro/offers/${window.location.host}/${params.id}`
    );

    const data = await result.json();

    setOffers(data.offers);
  };

  const createAllegroOfferUrl = (offer) => {
    const baseDomain = offer.publication.marketplaces.base.id.replace("-", ".");
    return `https://www.${baseDomain}/oferta/${offer.id}`;
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        sx={{ padding: "4rem", backgroundColor: "#FF6D1B", color: "#FFF" }}
      >
        <Typography variant="h2" fontWeight={"bold"}>
          {category.external_name}
        </Typography>
        {console.log(category)}
      </Grid>
      <Grid container sx={{ padding: "4rem" }}>
        <Grid container alignItems={"center"} justifyContent={"space-evenly"}>
          {offers.map((offer) => (
            <Grid key={offer.id} item sx={{ flexBasis: "30%" }}>
              <Grid
                container
                flexDirection={"column"}
                sx={{ padding: "2rem" }}
                alignItems={"center"}
              >
                <img
                  src={offer.primaryImage.url}
                  alt={`offer-${offer.id}`}
                  style={{ width: "90%" }}
                />
                <Typography variant="body1" sx={{ margin: "1.5rem 0rem" }}>
                  {offer.name}
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {offer.sellingMode.price.amount}{" "}
                  {offer.sellingMode.price.currency}
                </Typography>
                <Button
                  variant="outlined"
                  className={"btn-offer"}
                  LinkComponent={Link}
                  to={createAllegroOfferUrl(offer)}
                  target="_blank"
                >
                  Kup teraz
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
