import { Button, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { SettingsContext } from "../../context/SettingsContext";

export default function ContactSection() {
  const settings = useContext(SettingsContext);

  const [data, setData] = useState({ idOwner: settings.idOwner });
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleMessageDisplay = (msg = "") => {
    setMessage(msg);
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_CZATER_URL}/email/sendEmailFromCustomPage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      setData({ idOwner: settings.idOwner });
      handleMessageDisplay("Pomyślnie wysłano dane");
    } catch (error) {
      handleMessageDisplay("Wystąpił błąd podczas wysyłania danych");
    }
  };

  return (
    <Grid
      container
      id="contact"
      flexDirection={"column"}
      className="desc-contact-section"
    >
      <Typography variant="h2" fontWeight={"bold"}>
        Kontakt
      </Typography>
      <Typography fontSize={"1.5rem"} padding="1rem 0rem 0rem">
        Chcesz dowiedzieć się wiecej? Napisz do nas lub zadzwoń
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          className="contact-fields"
          sx={{
            padding: { xs: "0rem", md: "0rem 3rem 0rem 0rem" },
            borderRight: { xs: "0px", md: "2px solid white" },
            margin: { xs: "0rem", md: "2rem 0rem" },
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={4} sm={3}>
              <Typography>Imię i nazwisko</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <TextField
                fullWidth
                name="name"
                value={data.name}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container alignItems={"center"}>
            <Grid item xs={4} sm={3}>
              <Typography>Adres e-mail</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <TextField
                fullWidth
                name="email"
                value={data.email}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container alignItems={"center"}>
            <Grid item xs={4} sm={3}>
              <Typography>Telefon</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <TextField
                fullWidth
                name="phone"
                value={data.phone}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container alignItems={"center"}>
            <Grid item xs={4} sm={3}>
              <Typography>Treść wiadomości</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <TextField
                fullWidth
                multiline
                rows={5}
                name="message"
                value={data.message}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4} sm={3} />
            <Grid item xs={8} sm={9}>
              {showMessage ? <Typography>{message}</Typography> : ""}
              <Grid container justifyContent={"center"} flexDirection={"row"}>
                <Button
                  variant="outlined"
                  className="btn-contact-section"
                  onClick={handleFormSubmit}
                >
                  Wyślij
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            padding: { xs: "0rem", md: "4rem 3rem" },
          }}
        >
          {settings.company && Object.keys(settings.company).length > 0 ? (
            <>
              <Typography>
                <Typography>{settings?.company?.name}</Typography>
                <Typography>
                  {settings?.company?.street +
                    " " +
                    settings?.company?.property_number}
                  {settings?.company?.apartment_number
                    ? "/" + settings.company?.apartment_number
                    : ""}
                </Typography>
                <Typography>
                  {settings?.company?.city + " " + settings?.company?.zip_code}
                </Typography>
              </Typography>
              <br />
              <Typography>
                <Typography>NIP: {settings?.company?.nip}</Typography>
                <Typography>REGON: {settings?.company?.regon}</Typography>
                <Typography>KRS: {settings?.company?.krs}</Typography>
              </Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
